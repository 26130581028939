var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInformations,
            expression: "showInformations",
          },
        ],
        staticClass: "wrapper",
      },
      [
        _c(
          "div",
          { staticClass: "wrapper__card" },
          [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _vm._m(0),
              _c("div", { staticClass: "right" }, [
                _c(
                  "div",
                  [
                    _c("z-input", {
                      attrs: {
                        placeholder: "Pesquisar",
                        prependIcon: "$search_rounded",
                        type: "text",
                        hideDetails: true,
                      },
                      model: {
                        value: _vm.searchConnection,
                        callback: function ($$v) {
                          _vm.searchConnection = $$v
                        },
                        expression: "searchConnection",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "Nova jornada",
                        icon: "mdi-plus",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goToPage("journeyIndex")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("z-tabs", {
              attrs: { android: "", tabs: _vm.tabs },
              scopedSlots: _vm._u([
                {
                  key: "actives",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "wrapper__card__body" },
                        [
                          _c("z-pagination", {
                            attrs: {
                              "auto-pagination": "",
                              "icon-previous": "mdi-chevron-left",
                              "icon-next": "mdi-chevron-right",
                              "items-per-page": 9,
                              "number-of-items": _vm.searchConnection
                                ? _vm.filtered.length
                                : _vm.recent.lenth,
                              items: _vm.searchConnection
                                ? _vm.filtered
                                : _vm.recent,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "data",
                                fn: function ({ paginatedItems }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-wrapper" },
                                      [
                                        _c("z-table", {
                                          staticClass: "table",
                                          attrs: {
                                            headers: _vm.headers,
                                            items: paginatedItems,
                                            "icon-next": "mdi-chevron-right",
                                            "icon-previous": "mdi-chevron-left",
                                          },
                                          on: { sort: _vm.sortTable },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "no-data",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "no-data-table",
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Sem dados disponíveis."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: `item.favorite`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-center",
                                                      },
                                                      [
                                                        item.favorite
                                                          ? _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-not-filled-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.status`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-center",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.statusOpts[
                                                                  item.status
                                                                ]
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.name`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                      },
                                                      [
                                                        item.favorite
                                                          ? _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-not-filled-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name ||
                                                                  "Sem registro"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.actions`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "actions",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "img",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              staticStyle:
                                                                                {
                                                                                  "vertical-align":
                                                                                    "middle",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: "/icons/copy-icon.svg",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.duplicate(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "img",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Duplicar jornada"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        item.status ===
                                                          "awaiting-approval" &&
                                                        true
                                                          ? // permissions &&
                                                            // permissions.find(
                                                            //   (x) =>
                                                            //     x === '0d203848-c4ff-463b-b115-de93c3b5396b'
                                                            // )
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color: "grey",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-2",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.setJourneyToApprove(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "$check_circle_outline_rounded"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "white",
                                                                        padding:
                                                                          "0.5rem",
                                                                        "font-weight":
                                                                          "500",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Aprovar/Reprovar Jornada"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : item.status !==
                                                              "active-paused" &&
                                                            item.status !==
                                                              "inactive" &&
                                                            item.status !==
                                                              "awaiting-approval"
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color: "grey",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-2",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.openPauseDialog(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "$pause_circle_outline_rounded"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "white",
                                                                        padding:
                                                                          "0.5rem",
                                                                        "font-weight":
                                                                          "500",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Desativar"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : item.status ===
                                                              "inactive" ||
                                                            item.status ===
                                                              "active-paused"
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color: "grey",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-2",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.toggleJourneyStatus(
                                                                                            item,
                                                                                            "active"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "$play_circle_outline_rounded"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "white",
                                                                        padding:
                                                                          "0.5rem",
                                                                        "font-weight":
                                                                          "500",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Ativar"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : item.status ===
                                                            "rejected"
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color: "grey",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-2",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "red",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.setJourneyToSeeDetails(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "$zoox_alert"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "white",
                                                                        padding:
                                                                          "0.5rem",
                                                                        "font-weight":
                                                                          "500",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Jornada reprovada"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$router.push(
                                                                                      "/reports/journey-detail/" +
                                                                                        item.id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$insert_chart_outlined"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Relatórios"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      1
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$edit_outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Renomear"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      2
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$edit"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [_vm._v("Editar")]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      0
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$delete_outline_rounded"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [_vm._v("Apagar")]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.created`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formateDateTime(
                                                            item.created
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.lastUpdate`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.lastUpdate
                                                            ? _vm.formateDateTime(
                                                                item.lastUpdate
                                                              )
                                                            : "-"
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.nextUpdate`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.nextUpdate &&
                                                            item.status ===
                                                              "active"
                                                            ? _vm.formateDateTime(
                                                                item.nextUpdate
                                                              )
                                                            : "-"
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "drafts",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "wrapper__card__body" },
                        [
                          _c("z-pagination", {
                            attrs: {
                              "auto-pagination": "",
                              "icon-previous": "mdi-chevron-left",
                              "icon-next": "mdi-chevron-right",
                              "items-per-page": 9,
                              "number-of-items": _vm.searchConnection
                                ? _vm.filtered.length
                                : _vm.drafts.lenth,
                              items: _vm.searchConnection
                                ? _vm.filtered
                                : _vm.drafts,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "data",
                                fn: function ({ paginatedItems }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-wrapper" },
                                      [
                                        _c("z-table", {
                                          staticClass: "table",
                                          attrs: {
                                            headers: _vm.headers,
                                            items: paginatedItems,
                                            "icon-next": "mdi-chevron-right",
                                            "icon-previous": "mdi-chevron-left",
                                          },
                                          on: { sort: _vm.sortTable },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "no-data",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "no-data-table",
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Sem dados disponíveis."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: `item.favorite`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-center",
                                                      },
                                                      [
                                                        item.favorite
                                                          ? _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-not-filled-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.status`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-center",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.statusOpts[
                                                                  item.status
                                                                ]
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.name`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                      },
                                                      [
                                                        item.favorite
                                                          ? _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-not-filled-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name ||
                                                                  "Sem registro"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.actions`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "actions",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$router.push(
                                                                                      "/journey/" +
                                                                                        item.id +
                                                                                        "/reports"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$insert_chart_outlined"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Relatórios"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      1
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$edit_outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Renomear"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      2
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$edit"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [_vm._v("Editar")]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      0
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$delete_outline_rounded"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [_vm._v("Apagar")]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.created`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formateDateTime(
                                                            item.created
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.lastUpdate`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.lastUpdate
                                                            ? _vm.formateDateTime(
                                                                item.lastUpdate
                                                              )
                                                            : "-"
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.nextUpdate`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.nextUpdate &&
                                                            item.status ===
                                                              "active"
                                                            ? _vm.formateDateTime(
                                                                item.nextUpdate
                                                              )
                                                            : "-"
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c("z-card-modal", {
          attrs: { width: "400", title: "" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "delete-confirmation" }, [
                    _c(
                      "div",
                      { staticClass: "delete-confirmation__title mb-5" },
                      [
                        _c("span", [
                          _vm._v("Tem certeza que deseja apagar a jornada"),
                        ]),
                        _c("br"),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.journeyToDelete?.name ||
                                "Sem nome resgistrado"
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "delete-confirmation--actions" },
                      [
                        _c("z-btn", {
                          attrs: { small: "", rounded: "", text: "cancelar" },
                          on: {
                            click: function ($event) {
                              _vm.dialogDelete = false
                              _vm.resetjourneyToDelete()
                            },
                          },
                        }),
                        _c("z-btn", {
                          attrs: {
                            rounded: "",
                            primary: "",
                            text: "apagar",
                            small: "",
                            isLoading: _vm.loadingDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmedDeleteJourney()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        }),
        _c("z-card-modal", {
          attrs: { width: "400", title: "" },
          on: {
            "click:outside": function ($event) {
              return _vm.resetJourneyToRename()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _vm.journeyToUpdate?.id
                    ? _c("div", { staticClass: "delete-confirmation" }, [
                        _c(
                          "div",
                          { staticClass: "delete-confirmation__title mb-1" },
                          [
                            _c("span", { staticClass: "mb-5" }, [
                              _vm._v("Insira o novo nome para a jornada:"),
                            ]),
                            _c("z-input", {
                              staticClass: "mt-3",
                              model: {
                                value: _vm.journeyToUpdate.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.journeyToUpdate, "name", $$v)
                                },
                                expression: "journeyToUpdate.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "delete-confirmation--actions" },
                          [
                            _c("z-btn", {
                              attrs: {
                                small: "",
                                rounded: "",
                                text: "cancelar",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialogRename = false
                                  _vm.resetJourneyToRename()
                                },
                              },
                            }),
                            _c("z-btn", {
                              attrs: {
                                rounded: "",
                                primary: "",
                                text: "Renomear",
                                small: "",
                                isLoading: _vm.loadingRename,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmUpdateNameJourney()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogRename,
            callback: function ($$v) {
              _vm.dialogRename = $$v
            },
            expression: "dialogRename",
          },
        }),
        _c(
          "v-dialog",
          {
            attrs: { width: "900" },
            model: {
              value: _vm.approvalDialog,
              callback: function ($$v) {
                _vm.approvalDialog = $$v
              },
              expression: "approvalDialog",
            },
          },
          [
            _vm.approvalDialog
              ? _c("ApprovalModal", {
                  attrs: { "journey-prop": _vm.journeyToApprove },
                  on: {
                    close: function ($event) {
                      _vm.approvalDialog = false
                      _vm.journeyToApprove = null
                    },
                    "update-and-close": _vm.updateAndCloseApprovalJourney,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { width: "600" },
            model: {
              value: _vm.reasonForFailureDiaolg,
              callback: function ($$v) {
                _vm.reasonForFailureDiaolg = $$v
              },
              expression: "reasonForFailureDiaolg",
            },
          },
          [
            _vm.reasonForFailureDiaolg
              ? _c("ReasonForFailureModal", {
                  attrs: { "journey-prop": _vm.currentJourney },
                  on: {
                    close: function ($event) {
                      _vm.reasonForFailureDiaolg = false
                      _vm.currentJourney = null
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { width: "600" },
            model: {
              value: _vm.pauseJourneyDialog,
              callback: function ($$v) {
                _vm.pauseJourneyDialog = $$v
              },
              expression: "pauseJourneyDialog",
            },
          },
          [
            _vm.pauseJourneyDialog
              ? _c("PauseJourneyModal", {
                  attrs: { "journey-prop": _vm.currentJourney },
                  on: {
                    close: function ($event) {
                      _vm.pauseJourneyDialog = false
                      _vm.currentJourney = null
                    },
                    "update-and-close": _vm.updateAndClosePauseJourney,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showInformations,
            expression: "!showInformations",
          },
        ],
        staticClass: "wrapper no-data",
      },
      [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v("Consultar jornadas")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }