var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c("span", [
          _vm._v(
            "Jornada reprovada por " +
              _vm._s(_vm.journeyProp?.rejectedBy || "") +
              ":"
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "modal__header__right" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("$close_rounded")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "modal__body" }, [
      _c("div", { staticClass: "modal__body__journey" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.journeyProp?.rejectComment ||
                  "Sem registro de reprovação cadastrado."
              ) +
              " "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }