<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Consultar jornadas</span>
          </div>
          <div class="right">
            <div>
              <z-input
                v-model="searchConnection"
                placeholder="Pesquisar"
                prependIcon="$search_rounded"
                type="text"
                :hideDetails="true"
              />
            </div>
            <div>
              <z-btn
                rounded
                primary
                text="Nova jornada"
                icon="mdi-plus"
                small
                @click="goToPage('journeyIndex')"
              />
            </div>
          </div>
        </div>
        <z-tabs android :tabs="tabs">
          <template #actives>
            <div class="wrapper__card__body">
              <z-pagination
                auto-pagination
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="9"
                :number-of-items="
                  searchConnection ? filtered.length : recent.lenth
                "
                :items="searchConnection ? filtered : recent"
              >
                <template #data="{ paginatedItems }">
                  <div class="table-wrapper">
                    <z-table
                      class="table"
                      :headers="headers"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      @sort="sortTable"
                    >
                      <template #no-data>
                        <div class="no-data-table">
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>
                      <template v-slot:[`item.favorite`]="{ item }">
                        <div class="d-flex align-center justify-center">
                          <img
                            v-if="item.favorite"
                            :style="iconStyle"
                            src="/icons/favorite-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <img
                            v-else
                            :style="iconStyle"
                            src="/icons/favorite-not-filled-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                        </div>
                      </template>

                      <template v-slot:[`item.status`]="{ item }">
                        <div class="d-flex align-center justify-center">
                          <span>
                            {{ statusOpts[item.status] }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.name`]="{ item }">
                        <div class="d-flex align-center">
                          <img
                            v-if="item.favorite"
                            :style="iconStyle"
                            src="/icons/favorite-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <img
                            v-else
                            :style="iconStyle"
                            src="/icons/favorite-not-filled-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <span>
                            {{ item.name || "Sem registro" }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="actions">
                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                style="vertical-align: middle; cursor: pointer"
                                @click="duplicate(item)"
                                src="/icons/copy-icon.svg"
                              />
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Duplicar jornada</span
                            >
                          </v-tooltip>
                          <v-tooltip
                            v-if="
                              item.status === 'awaiting-approval' && true
                              // permissions &&
                              // permissions.find(
                              //   (x) =>
                              //     x === '0d203848-c4ff-463b-b115-de93c3b5396b'
                              // )
                            "
                            bottom
                            color="grey"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="setJourneyToApprove(item)"
                                >$check_circle_outline_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Aprovar/Reprovar Jornada</span
                            >
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            color="grey"
                            v-else-if="
                              item.status !== 'active-paused' &&
                              item.status !== 'inactive' &&
                              item.status !== 'awaiting-approval'
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="openPauseDialog(item)"
                                >$pause_circle_outline_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Desativar</span
                            >
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            color="grey"
                            v-else-if="
                              item.status === 'inactive' ||
                              item.status === 'active-paused'
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="toggleJourneyStatus(item, 'active')"
                                >$play_circle_outline_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Ativar</span
                            >
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            color="grey"
                            v-else-if="item.status === 'rejected'"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                color="red"
                                @click="setJourneyToSeeDetails(item)"
                                >$zoox_alert</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Jornada reprovada</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="
                                  $router.push(
                                    '/reports/journey-detail/' + item.id
                                  )
                                "
                                >$insert_chart_outlined</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Relatórios</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 1)"
                                >$edit_outline</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Renomear</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 2)"
                                >$edit</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Editar</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 0)"
                                >$delete_outline_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Apagar</span
                            >
                          </v-tooltip>
                        </div>
                      </template>

                      <template v-slot:[`item.created`]="{ item }">
                        <span>{{ formateDateTime(item.created) }} </span>
                      </template>

                      <template v-slot:[`item.lastUpdate`]="{ item }">
                        <span
                          >{{
                            item.lastUpdate
                              ? formateDateTime(item.lastUpdate)
                              : "-"
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.nextUpdate`]="{ item }">
                        <span
                          >{{
                            item.nextUpdate && item.status === "active"
                              ? formateDateTime(item.nextUpdate)
                              : "-"
                          }}
                        </span>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
            </div>
          </template>
          <template #drafts>
            <div class="wrapper__card__body">
              <z-pagination
                auto-pagination
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="9"
                :number-of-items="
                  searchConnection ? filtered.length : drafts.lenth
                "
                :items="searchConnection ? filtered : drafts"
              >
                <template #data="{ paginatedItems }">
                  <div class="table-wrapper">
                    <z-table
                      class="table"
                      :headers="headers"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      @sort="sortTable"
                    >
                      <template #no-data>
                        <div class="no-data-table">
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>
                      <template v-slot:[`item.favorite`]="{ item }">
                        <div class="d-flex align-center justify-center">
                          <img
                            v-if="item.favorite"
                            :style="iconStyle"
                            src="/icons/favorite-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <img
                            v-else
                            :style="iconStyle"
                            src="/icons/favorite-not-filled-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                        </div>
                      </template>

                      <template v-slot:[`item.status`]="{ item }">
                        <div class="d-flex align-center justify-center">
                          <span>
                            {{ statusOpts[item.status] }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.name`]="{ item }">
                        <div class="d-flex align-center">
                          <img
                            v-if="item.favorite"
                            :style="iconStyle"
                            src="/icons/favorite-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <img
                            v-else
                            :style="iconStyle"
                            src="/icons/favorite-not-filled-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <span>
                            {{ item.name || "Sem registro" }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="actions">
                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="
                                  $router.push(
                                    '/journey/' + item.id + '/reports'
                                  )
                                "
                                >$insert_chart_outlined</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Relatórios</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 1)"
                                >$edit_outline</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Renomear</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 2)"
                                >$edit</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Editar</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 0)"
                                >$delete_outline_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Apagar</span
                            >
                          </v-tooltip>
                        </div>
                      </template>

                      <template v-slot:[`item.created`]="{ item }">
                        <span>{{ formateDateTime(item.created) }} </span>
                      </template>

                      <template v-slot:[`item.lastUpdate`]="{ item }">
                        <span
                          >{{
                            item.lastUpdate
                              ? formateDateTime(item.lastUpdate)
                              : "-"
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.nextUpdate`]="{ item }">
                        <span
                          >{{
                            item.nextUpdate && item.status === "active"
                              ? formateDateTime(item.nextUpdate)
                              : "-"
                          }}
                        </span>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
            </div>
          </template>
        </z-tabs>
      </div>

      <z-card-modal v-model="dialogDelete" width="400" title="">
        <template #content>
          <div class="delete-confirmation">
            <div class="delete-confirmation__title mb-5">
              <span>Tem certeza que deseja apagar a jornada</span>
              <br />
              <strong>{{
                journeyToDelete?.name || "Sem nome resgistrado"
              }}</strong>
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="
                  dialogDelete = false;
                  resetjourneyToDelete();
                "
              />

              <z-btn
                rounded
                primary
                text="apagar"
                small
                :isLoading="loadingDelete"
                @click="confirmedDeleteJourney()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>

      <z-card-modal
        v-model="dialogRename"
        width="400"
        title=""
        @click:outside="resetJourneyToRename()"
      >
        <template #content>
          <div v-if="journeyToUpdate?.id" class="delete-confirmation">
            <div class="delete-confirmation__title mb-1">
              <span class="mb-5">Insira o novo nome para a jornada:</span>
              <z-input v-model="journeyToUpdate.name" class="mt-3" />
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="
                  dialogRename = false;
                  resetJourneyToRename();
                "
              />

              <z-btn
                rounded
                primary
                text="Renomear"
                small
                :isLoading="loadingRename"
                @click="confirmUpdateNameJourney()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>

      <v-dialog v-model="approvalDialog" width="900">
        <ApprovalModal
          v-if="approvalDialog"
          :journey-prop="journeyToApprove"
          @close="
            approvalDialog = false;
            journeyToApprove = null;
          "
          @update-and-close="updateAndCloseApprovalJourney"
        />
      </v-dialog>

      <v-dialog v-model="reasonForFailureDiaolg" width="600">
        <ReasonForFailureModal
          v-if="reasonForFailureDiaolg"
          :journey-prop="currentJourney"
          @close="
            reasonForFailureDiaolg = false;
            currentJourney = null;
          "
        />
      </v-dialog>

      <v-dialog v-model="pauseJourneyDialog" width="600">
        <PauseJourneyModal
          v-if="pauseJourneyDialog"
          :journey-prop="currentJourney"
          @close="
            pauseJourneyDialog = false;
            currentJourney = null;
          "
           @update-and-close="updateAndClosePauseJourney"
        />
      </v-dialog>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
import ApprovalModal from "./components/appovalModal.vue";
import ReasonForFailureModal from "./components/reasonForFailureModal.vue";

import PauseJourneyModal from "./components/pauseJourneyModal.vue";

export default {
  components: {
    ApprovalModal,
    ReasonForFailureModal,
    PauseJourneyModal,
  },
  data() {
    return {
      currentJourney: null,
      reasonForFailureDiaolg: false,
      pauseJourneyDialog: false,
      sortDesc: false,
      approvalDialog: false,
      headers: [
        // { text: "", value: "favorite", sortable: false },
        { text: "Nome", value: "name", sortable: true },
        { text: "Status", value: "status", sortable: false },
        { text: "Data de criação", value: "created", sortable: true },
        { text: "Última Execução", value: "lastUpdate", sortable: true },
        { text: "Próxima Execução", value: "nextUpdate", sortable: true },
        { text: "Ações", value: "actions" },
      ],
      journeyToApprove: null,
      journeyToDelete: null,
      journeyToUpdate: null,
      loadingDelete: false,
      dialogDelete: false,
      dialogRename: false,
      loadingRename: false,
      statusOpts: {
        active: "Ativa",
        inactive: "Inativa",
        "awaiting-approval": "Aprovação Pendente",
        finished: "Finalizada",
        "active-paused": "Aguardando iniciar",
        rejected: "Rejeitada",
        draft: "Rascunho",
        "in-queue": "Na Fila",
        "creating-users": "Criando Audiencia",
        sending: "Enviando",
        "no-audiences": "Erro - Sem Audiencias",
        "no-interactions": "Erro - Sem Envios",
      },
      filtered: [],
      searchConnection: "",
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      iconStyle2: {
        width: "23px",
        height: "23px",
        cursor: "pointer",
      },
      http: new ApiClient(),
      showAudienceModal: false,
      audienceModalData: [
        {
          name: "Criar audiência visão 360",
          description: "Criar uma nova audiência usando a visão 360.",
          icon: "/icons/people-icon.svg",
          to: "/",
        },
        {
          name: "Criar audiência filtrada",
          description: "Criar uma audiência com filtragem avançada.",
          icon: "/icons/search-icon.svg",
          to: "/",
        },
        {
          name: "Selecionar audiência",
          description: "Selecionar uma audiência já criada anteriormente.",
          icon: "/icons/folder-icon.svg",
          to: "/",
        },
      ],
      tabs: [
        { id: "actives", text: "Jornadas ativas" },
        { id: "drafts", text: "Jornadas rascunho" },
      ],
      optionsMenu: [
        { action: "delete", text: "Apagar", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
        { action: "document", text: "Documentação", value: 2 },
        { action: "log", text: "Ver log", value: 3 },
      ],
      optionsMenu2: [
        { action: "delete", text: "Apagar", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
        { action: "log", text: "Ver log", value: 3 },
      ],
      stats: {
        pending: "Pendente",
        updated: "Atualizado",
        error: "Error",
        "in-progress": "Em progresso",
      },
      cardAudienceStyle: {
        background: "#FFFFFF",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: "1rem",
        flex: "1 1 200px",
        cursor: "pointer",
      },
      cardAudienceStyle2: {
        display: "flex",
        alignItems: "flex-start",
        gap: "1rem",
        padding: "1rem 0",
        flexWrap: "wrap",
        color: "#333333",
      },
      recent: [],
      drafts: [],
      showInformations: false,
      fetchIntervalFn: null,
    };
  },
  watch: {
    dialogDelete(nv) {
      if (!nv) this.resetjourneyToDelete();
    },
    searchConnection(nv) {
      this.filtered = this.getConnectionsByName(nv);
    },
  },
  beforeDestroy() {
    if (this.fetchIntervalFn) {
      clearInterval(this.fetchIntervalFn);
    }
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("zooxeye-user"));
    if (this.user && this.user.permissions)
      this.permissions = this.user.permissions;

    this.getJourneyList();

    this.fetchIntervalFn = setInterval(async () => {
      await this.getJourneyList();
    }, 10000);
  },
  computed: {
    getFavorites() {
      return !this.searchConnection
        ? this.recent.filter((item) => item.favorite == true)
        : this.filtered.filter((item) => item.favorite == true);
    },
  },
  methods: {
    updateAndCloseApprovalJourney() {
      this.approvalDialog = false;
      this.getJourneyList();
    },
    updateAndClosePauseJourney() {
      this.pauseJourneyDialog = false;
      this.getJourneyList();
    },
    setJourneyToSeeDetails(item) {
      this.currentJourney = item;
      this.reasonForFailureDiaolg = true;
    },
    openPauseDialog(item) {
      this.currentJourney = item;
      this.pauseJourneyDialog = true;
    },
    setJourneyToApprove(item) {
      this.journeyToApprove = item;
      this.approvalDialog = true;
    },
    genericSortElements(arr, nameToSort) {
      return arr.sort((a, b) =>
        a[nameToSort] > b[nameToSort]
          ? 1
          : b[nameToSort] > a[nameToSort]
          ? -1
          : 0
      );
    },
    async toggleJourneyStatus(item, status) {
      try {
        const req = await this.http.post(`journey/changeStatus`, {
          id: item.id,
          status: status,
        });
        this.getJourneyList();
        if (req) {
          this.$toast.success("Jornada atualizada com sucesso!");
        }
      } catch (e) {
        this.$toast.error(e.body.detail);
      }
    },
    async duplicate(item) {
      try {
        const req = await this.http.post(`journey/duplicate?id=${item.id}`, {});
        this.getJourneyList();
        if (req) {
          this.$toast.success("Jornada atualizada com sucesso!");
        }
      } catch (e) {
        this.$toast.error(e.body.detail);
      }
    },
    getConnectionsByName(name) {
      return this.recent.filter((item) =>
        item.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
    },
    resetjourneyToDelete() {
      this.journeyToDelete = null;
    },
    resetJourneyToRename() {
      this.journeyToUpdate = null;
      this.dialogRename = false;
    },
    async confirmedDeleteJourney() {
      this.loadingDelete = true;
      try {
        const req = await this.http.delete(
          `journey/${this.journeyToDelete.id}`
        );
        if (req) {
          this.$toast.success("Jornada apagada com sucesso!");
          this.resetjourneyToDelete();
          await this.getJourneyList();
          this.filtered =
            this.getConnectionsByName(this.searchConnection) || [];
          this.dialogDelete = false;
        }
        this.loadingDelete = false;
      } catch (error) {
        this.$toast.error("Erro ao deletar Jornada");
        this.loadingDelete = false;
      }
    },
    async confirmUpdateNameJourney() {
      this.loadingRename = true;
      try {
        const req = await this.http.post(`journey/rename`, {
          id: this.journeyToUpdate.id,
          name: this.journeyToUpdate.name,
        });
        if (req) {
          this.$toast.success("Jornada renomeada com sucesso!");
          this.getJourneyList();
          this.loadingRename = false;
          this.resetJourneyToRename();
        }
        this.loadingRename = false;
        this.journeyToUpdate = null;
      } catch (error) {
        this.$toast.error("Erro ao renomear conexão");
        this.loadingRename = false;
      }
      this.loadingRename = false;
    },
    selectOptionFromTable(data, option) {
      switch (option) {
        case 0:
          this.deleteConnection(data);
          break;
        case 1:
          this.updateConnection(data);
          break;
        case 2:
          this.$router.push({
            name: "journeyEditPage",
            params: { id: data.id },
          });
          break;
        case 3:
          //   this.$router.push({
          //     name: "logsIndexPage",
          //     params: { page: "primaryData", id: data.id },
          //   });
          break;
      }
    },
    deleteConnection(connection) {
      this.journeyToDelete = connection;
      this.dialogDelete = true;
    },
    updateConnection(connection) {
      this.journeyToUpdate = { ...connection };
      this.dialogRename = true;
    },

    isImageLoaded() {
      // this.showInformations = isLoaded
    },
    setBadgeTheme(item) {
      const DEFAULT = {
        backgroundColor: "#d0294b",
        color: "#ffffff",
      };

      const STYLES = {
        updated: {
          backgroundColor: "#49c469",
          color: "#ffffff",
        },
        pending: {
          backgroundColor: "#FFC220",
          color: "#ffffff",
        },
        "in-progress": {
          backgroundColor: "#ff9320",
          color: "#ffffff",
        },
        error: { ...DEFAULT },
      };

      return STYLES[item] || DEFAULT;
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formateDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    async getJourneyList() {
      try {
        const req = await this.http.get(`journey/list`);
        this.recent = req;
        this.drafts = this.recent.filter((x) => x.status === "draft");
        this.recent = this.recent.filter((x) => x.status !== "draft");
        this.showInformations = true;
      } catch (error) {
        this.$toast.error("Erro ao listar jornadas");
        this.showInformations = true;
      }
    },
    async sortTable(nameToSort) {
      this.sortDesc = !this.sortDesc;
      try {
        const ORDER = this.sortDesc ? "ASC" : "DESC";
        const response = await this.http.get(
          `journey/list?sort=${nameToSort}&order=${ORDER}`
        );
        if (response) {
          this.recent = response;
          if (this.searchConnection) {
            this.filtered =
              this.getConnectionsByName(this.searchConnection) || [];
          }
        }
      } catch (error) {
        this.$toast.error("Erro ao ordenar dados");
      }
    },
    async favorite(item) {
      let fav = item.favorite;
      if (fav == null || fav == undefined) {
        fav = false;
        try {
          await this.http.post("journey/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getJourneyList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      } else {
        fav = !fav;
        try {
          await this.http.post("journey/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getJourneyList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;

  // height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;

      .left {
        flex: 1 1 auto;

        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }

      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;

          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;

      .table-wrapper {
        height: 500px !important;
        max-height: 550px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }
        ::v-deep .v-data-table {
          table {
            th {
              &:first-child {
                min-width: 350px;
                max-width: 350px;
              }
              &:last-child {
                min-width: 180px;
                max-width: 180px;
              }
              &:nth-child(2) {
                min-width: 200px;
                max-width: 200px;
              }
            }
            td {
              font-size: 0.8rem;
            }
          }
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 650px;
        }
      }

      @media (min-width: 720px) and (max-width: 1079px) {
        .table-wrapper {
          height: 400px;
          max-height: 400px;
        }
      }

      @media (max-width: 890px) {
        height: auto;

        .table-wrapper {
          height: 350px;
          max-height: 350px;
        }
      }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:first-child) {
                text-align: center !important;
              }

              &:last-child {
                text-align: right !important;
              }
            }

            td {
              &:not(:first-child) {
                text-align: center !important;

                & > div,
                span {
                  margin: 0 auto !important;
                }
              }

              &:last-child {
                text-align: right !important;
              }
            }
          }
        }
      }
    }
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }

    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;

  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;

    span {
      font-weight: 500;
    }

    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;

  &__title {
    text-align: center;
    font-weight: 500;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
