var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "modal__header__right" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("$close_rounded")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal__body" },
      [
        _c(
          "v-radio-group",
          {
            attrs: {
              rules: [(v) => !!v || "Campo obrigatório"],
              "hide-details": "",
              small: "",
            },
            model: {
              value: _vm.pauseType,
              callback: function ($$v) {
                _vm.pauseType = $$v
              },
              expression: "pauseType",
            },
          },
          [
            _c("v-radio", {
              staticStyle: { width: "350px" },
              attrs: { value: "keep", small: "" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("span", { staticStyle: { color: "#000000" } }, [
                        _vm._v("Manter os envios na fila"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("v-radio", {
              staticStyle: { width: "350px" },
              attrs: { value: "remove" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("span", { staticStyle: { color: "#000000" } }, [
                        _vm._v("Remover os envios da fila"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c("z-btn", {
          staticStyle: { color: "white" },
          attrs: {
            disabled: _vm.loadingInfo,
            iconRight: true,
            "is-loading": _vm.loadingReject,
            color: "red",
            text: "Pausar jornada",
          },
          on: { click: _vm.confirmPause },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__left" }, [
      _c("span", [_vm._v("Escolha o comportamento para a jornada pausada:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }