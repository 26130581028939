var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "modal__header__right" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("$close_rounded")]
          ),
        ],
        1
      ),
    ]),
    !_vm.loadingInfo
      ? _c("div", { staticClass: "modal__body" }, [
          _c(
            "div",
            { staticClass: "modal__body__journey" },
            [
              _vm.tree != null && !_vm.loadingInfo
                ? _c("TreeComponent", { attrs: { nodes: _vm.tree } })
                : _c("div", [_c("span", [_vm._v("Dados não encontrados")])]),
            ],
            1
          ),
          _vm.isToReject
            ? _c(
                "div",
                { staticClass: "modal__body__form" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c("z-input-area", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          hideDetails: true,
                          rules: [(v) => !!v || "Campo obrigatório"],
                          rows: 1,
                          placeholder: "Escreva o motivo",
                        },
                        model: {
                          value: _vm.rejectComment,
                          callback: function ($$v) {
                            _vm.rejectComment = $$v
                          },
                          expression: "rejectComment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          { staticClass: "modal__body d-flex align-center justify-center" },
          [_c("z-loading")],
          1
        ),
    _c("div", { staticClass: "modal--actions" }, [
      _c(
        "div",
        { staticClass: "reject d-flex" },
        [
          _c("z-btn", {
            staticStyle: { color: "white" },
            attrs: {
              text: _vm.rejectButtonText,
              iconRight: true,
              disabled: _vm.loadingInfo,
              color: "red",
              primary: "",
              icon: "$close_rounded",
            },
            on: { click: _vm.setItemToReject },
          }),
          _vm.isToReject
            ? _c("z-btn", {
                staticStyle: { color: "white" },
                attrs: {
                  disabled: _vm.loadingInfo,
                  iconRight: true,
                  "is-loading": _vm.loadingReject,
                  color: "red",
                  text: "Rejeitar jornada",
                },
                on: { click: _vm.confirmReject },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.isToReject
        ? _c(
            "div",
            { staticClass: "approve" },
            [
              _c("z-btn", {
                staticStyle: { color: "white" },
                attrs: {
                  iconRight: true,
                  disabled: _vm.loadingInfo,
                  "is-loading": _vm.loadingApprove,
                  icon: "$check",
                  primary: "",
                  color: "green",
                  text: "Aprovar e ativar jornada",
                },
                on: { click: _vm.confirmApproved },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__left" }, [
      _c("span", [_vm._v("Aprovação de jornada")]),
      _c("br"),
      _c("small", [_vm._v("Selecione abaixo a opção desejada")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }